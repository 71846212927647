<template>
    <div style="background: var(--v-component-base) !important">
        <v-row justify="end" class="mb-2">
            <v-col cols="12" sm="12" md="4" lg="6" class="text-left">
                <v-btn v-if="params.filter && Object.keys(params.filter).length > 0" type="plain" @click="clearFilter"
                    color="error">
                    <v-icon small>close</v-icon> Clear Filters
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="6">
                <el-input suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search">
                    <template slot="prepend">
                        <el-button id="add-user-button" @click="addUserDialog()"><i class="el-icon-plus"></i>
                        </el-button>
                    </template>
                </el-input>
            </v-col>
        </v-row>

        <v-row class="mt-3">
            <v-col cols="12">
                <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" dense :items="filterOrgUsers"
                    :loading="loading" :headers="headers" @dblclick:row="editUserDialog" style="cursor: pointer;">
                    <template v-slot:[`item.shared`]="{ item, index }">
                        <v-icon @click="updateShareStatus(item)" :id="'user-shared-column_' + index" v-if="item.shared"
                            color="success">check_circle_outline</v-icon>
                        <v-icon @click="updateShareStatus(item)" :id="'user-shared-column_' + index" v-else
                            color="danger">
                            cancel
                        </v-icon>
                    </template>
                    <template v-slot:[`item.roles`]="{ item, index }">
                        <div v-if="!item.addRole" :key="tabRefresh" :id="'user-roles-column_' + index"
                            style="width:100; padding: 5px">
                            <el-tag v-for="(tag) in item.user.userRoles" :key="tag.id" class="mr-1 mt-1" size="small">
                                {{ tag.role.name }}
                            </el-tag>
                            <v-btn class="pa-1 ma-2" icon small @click="addUserToRole(item)">
                                <v-icon>add_circle_outline</v-icon>
                            </v-btn>
                        </div>
                        <v-autocomplete :key="tabRefresh" deletable-chips dense multiple rounded small-chips outlined
                            @blur="processUserRoleChanges(item)"
                            :items="roles.filter(x => !item.user.userRoles.map(y => y.id).includes(x.id))"
                            :ref="'addToRoleInput_' + item.id" v-if="item.addRole" item-text="name" item-value="id"
                            v-model="item.userRoles"></v-autocomplete>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" class="text-center">
                <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
                    :page-size.sync="params.limit" :page-sizes="[15, 25, 50, 100]"
                    :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'prev, pager, next'"
                    :total="orgUsers.total">
                </el-pagination>
            </v-col>
        </v-row>

        <v-dialog v-model="userDialog" persistent max-width="900px">
            <v-card dark :loading="loading">
                <v-toolbar flat color="transparent">
                    <v-card-title>
                        <span class="headline">{{ `${modalType} User` }}</span>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <div v-if="modalType == 'Edit'">
                        <v-btn plain depressed elevation="0" @click="removeUser()">
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </div>
                    <v-btn plain depressed elevation="0" :disabled="!validateEmail(contact.emailAddress)"
                        @click="modalType == 'Edit' ? saveUser() : addUser()">
                        <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn plain depressed elevation="0" @click="clearDialog()">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row class="text-center">
                        <v-col>
                            <v-avatar class="ma-2" size="128">
                                <img v-if="contact.avatar" :src="contact.avatar">
                            </v-avatar>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-container grid-list-md>
                                <v-text-field dense outlined v-model="contact.firstname" label="First Name*">
                                </v-text-field>
                                <v-text-field dense outlined v-model="contact.surname" label="Last Name*">
                                </v-text-field>
                                <v-text-field dense outlined v-model="contact.position" label="Company Position*">
                                </v-text-field>
                                <v-text-field dense outlined v-model="contact.emailAddress" label="Email*">
                                    <v-icon v-if="validateEmail(contact.emailAddress)" slot="append" class="mr-1" small
                                        color="green">check</v-icon>
                                    <v-icon v-else slot="append" class="mr-1" small color="orange">warning</v-icon>
                                </v-text-field>
                                <v-text-field dense outlined v-model="contact.mobileNumber" label="Mobile Number">
                                </v-text-field>
                                <small>*indicates required field</small>
                            </v-container>
                        </v-col>
                        <v-col>
                            <v-container grid-list-md>
                                <v-select dense outlined v-model="contact.authProvider" label="Authentication Provider*"
                                    :items="['Google', 'system']"></v-select>
                                <v-select multiple chips outlined closable :items="orgTeams" label="User Teams"
                                    item-value="id" item-text="name" v-model="selectedTeams" append-icon="groups">
                                    <template #selection="{ item }">
                                        <v-chip :color="item.colour">{{ item.name }}</v-chip>
                                    </template>
                                </v-select>
                                <v-select multiple chips outlined closable :items="roles" label="User Roles"
                                    item-value="id" item-text="name" v-model="selectedRoles"
                                    append-icon="manage_accounts">
                                </v-select>
                                <v-checkbox v-model="contact.shared" label="Shared Contact"></v-checkbox>
                                <small>This is someone who represents your company when someone searches for your
                                    business in our system.</small>
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>
    
<script>
export default {
    props: ['orgId'],
    data: () => ({
        headers: [{
            text: 'Name',
            value: 'user.firstname',
            align: 'center',
        },
        {
            text: 'Surname',
            value: 'user.surname',
            align: 'center',
        },
        {
            text: 'Email',
            value: "user.emailAddress",
            align: 'center',
        },
        {
            text: 'Position',
            value: "position",
            align: 'center',
        },
        {
            text: 'Shared',
            value: "shared",
            align: 'center',
        },
        {
            text: 'Auth Provider',
            sortable: false,
            value: "user.authProvider",
            align: 'center',
        },
        {
            text: 'Roles',
            value: 'roles',
            sortable: false,
            align: 'center',
            width: '250px'
        }],
        integrations: [],
        integrationAuths: [],
        contact: {},
        modalType: '',
        loading: false,
        orgUsers: [],
        orgTeams: [],
        contactTeams: [],
        selectedTeams: [],
        roles: [],
        contactRoles: [],
        selectedRoles: [],
        tabRefresh: 0,
        tempUsers: [],
        sortBy: 'user.firstname',
        sortDesc: false,
        users: {
            count: 0,
            data: []
        },
        userDialog: false,
        page: 1,
        params: {
            limit: 100,
            offset: 0,
            search: null
        }
    }),
    computed: {

    },
    watch: {
        sortBy(value) {
            console.log(value)
        }
    },
    async created() {
        await this.getIntegrations()
    },
    async mounted() {
        this.getIntegrations()
    },
    methods: {
        async getIntegrations() {
            let result = await this.$API.getIntegrationsAdmin(this.orgId)
            this.integrations = result
        },
        async getIntegrationAuths() {
            // let result = await this.$API.getIntegrationsAuthAdmin(this.orgId)
            // this.integrationAuths = result
        },
        async addUser() {
            try {
                this.loading = true;
                let contact = await this.$API.createUserAdmin(this.contact)
                let orgUser = await this.$API.createOrganisationUserAdmin({
                    userId: contact.id,
                    shared: this.contact.shared,
                    position: this.contact.position,
                    organisationId: this.orgId
                })
                this.contact.orgUserId = orgUser.id
                this.contact.id = contact.id
                await this.saveTeamsAndRoles()
                this.loading = false
                this.clearDialog()
                this.$message({
                    type: 'success',
                    message: 'User created successfully!'
                })
            } catch (e) {
                this.$message({
                    type: 'warning',
                    message: 'Creating user failed.'
                })
            }
        },
        async saveUser() {
            try {
                this.loading = true;
                await this.$API.updateUserAdmin({
                    id: this.contact.id,
                    firstname: this.contact.firstname,
                    surname: this.contact.surname,
                    emailAddress: this.contact.emailAddress,
                    authProvider: this.contact.authProvider,
                    mobileNumber: this.contact.mobileNumber
                })
                await this.$API.updateOrganisationUserAdmin({
                    id: this.contact.orgUserId,
                    shared: this.contact.shared,
                    position: this.contact.position
                })
                await this.saveTeamsAndRoles()
                this.loading = false;
                this.clearDialog()
                this.$message({
                    type: 'success',
                    message: 'User saved successfully!'
                })
            } catch (e) {
                this.$message({
                    type: 'warning',
                    message: 'User update failed.'
                })
            }

        },
        addUserToRole(user) {
            let index = this.orgUsers.indexOf(this.orgUsers.find(x => x.id == user.id))
            this.orgUsers[index].addRole = true
            this.orgUsers[index].userRoles = this.orgUsers[index].user.userRoles.map(x => x.roleId)
            this.tabRefresh++
            this.$nextTick(() => {
                this.$refs['addToRoleInput_' + user.id].$refs.input.focus();
            });
        },
        clearDialog() {
            this.userDialog = false
            this.contact = {}
            this.selectedRoles = []
            this.contactRoles = []
            this.contactTeams = []
            this.selectedTeams = []
            this.getOrganisationUsers()
        },
        async processUserRoleChanges(data) {
            let index = this.orgUsers.indexOf(this.orgUsers.find(x => x.id == data.id))
            this.orgUsers[index].addRole = false
            let add = data.userRoles.filter(x => !data.user.userRoles.map(y => y.roleId).includes(x))
            let remove = data.user.userRoles.filter(x => !data.userRoles.includes(x.roleId))
            let obj = [...add.map(x => ({
                userId: data.userId,
                roleId: x
            })), ...remove.map(x => ({
                id: x.id,
                isActive: false,
                isDeleted: true
            }))]
            if (obj.length > 0) {
                await this.$API.createUserRoleAdmin({
                    batch: obj
                })
                this.getOrganisationUsers()
            }
            this.tabRefresh++
        },
        filterUserChange(data) {
            this.params.filter = data
            // this.getUsers()
        },
        async addUserDialog() {
            this.contact = {};
            this.modalType = 'Add'
            this.userDialog = true;
        },
        async saveTeamsAndRoles() {
            let selectedRoles = this.roles.filter(role => this.selectedRoles.includes(role.id))
            await this.addUserRoles(this.getDifference(selectedRoles, this.contactRoles))
            await this.removeUserRoles(this.getDifference(this.contactRoles, selectedRoles))
            let selectedTeams = this.orgTeams.filter(team => this.selectedTeams.includes(team.id))
            await this.createIntegration(this.getDifference(selectedTeams, this.contactTeams))
            await this.updateIntegration(this.getDifference(this.contactTeams, selectedTeams))
        },
        async addUserRoles(roles) {
            for (let i = 0; i < roles.length; i++) {
                await this.$API.createUserRoleAdmin({
                    roleId: roles[i].id,
                    userId: this.contact.id
                })
            }
        },
        async removeUserRoles(roles) {
            for (let i = 0; i < roles.length; i++) {
                let userRoleId = this.contact.roles.filter(x => x.roleId == roles[i].id)
                await this.$API.updateUserRoleAdmin({
                    id: userRoleId[0].id,
                    isActive: false,
                    isDeleted: true
                })
            }
        },
        async createIntegration(teams) {
            for (let i = 0; i < teams.length; i++) {
                let userTeam = await this.$API.createIntegrationAdmin({
                    teamId: teams[i].id,
                    organisationUserId: this.contact.orgUserId
                })
                this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams.push(userTeam)
            }
        },
        async updateIntegration(teams) {
            for (let i = 0; i < teams.length; i++) {
                let userTeam = teams[i].userTeams.filter(x => x.organisationUserId == this.contact.orgUserId)
                await this.$API.updateIntegrationAdmin({
                    id: userTeam[0].id,
                    isActive: false,
                    isDeleted: true
                })
                this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams
                    .splice(this.orgTeams[this.orgTeams.indexOf(teams[i])].userTeams.indexOf(userTeam[0]), 1)
            }
        },
        async editUserDialog(click, row) {
            console.log(row.item)
            this.modalType = 'Edit'
            this.contact = {
                id: row.item.user.id,
                orgUserId: row.item.id,
                firstname: row.item.user.firstname,
                surname: row.item.user.surname,
                emailAddress: row.item.user.emailAddress,
                position: row.item.position,
                shared: row.item.shared,
                authProvider: row.item.user.authProvider,
                mobileNumber: row.item.user.mobileNumber,
                avatar: row.item.user.avatar,
                roles: row.item.user.userRoles
            }
            this.selectedRoles = row.item.user.userRoles.map(x => x.roleId)
            this.contactRoles = this.roles.filter(x => this.selectedRoles.includes(x.id))
            this.contactTeams = this.orgTeams.filter(x => x.userTeams.some(y => y.organisationUserId == this.contact.orgUserId))
            this.selectedTeams = this.contactTeams.map(x => x.id)
            this.userDialog = true
        },
        async removeUser() {
            this.$confirm('Are you sure you want to delete this user?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                await this.$API.updateOrganisationUserAdmin({
                    id: this.contact.orgUserId,
                    isActive: false,
                    isDeleted: true
                })
                this.clearDialog()
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'warning',
                    message: 'Delete canceled.'
                });
            });
        }
    }
}
</script>
